﻿.component-error {
    background-color: #fff6f6;
    color: #9f3a38;
    box-shadow: none;
    border-radius: 0;
    border: 1px solid #e0b4b4;
    padding: 5px;
    margin-bottom: 5px;
}

.field-validation-error {
    color: #9f3a38;
    padding-left: 5px;
    font-weight: 700;
} 

h3 {
    font-size: 1.6rem;
}

.ols-table {
    border: 1px solid #78C0D4;
}

.ols-row {
    background: #A5D5E2;
}

div.ols-table > div:nth-of-type(odd) {
    background: #D2EAF1;
}

.ols-row-cell {
    height: 90px;
    border: 1px solid #78C0D4;
}

#contact-us-btn {
    margin-top: 18px;
    margin-left: 25px;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media only screen and (min-width: 600px) {
    .nhs-wales-logo {
        width: 50%;
    }
}

@media only screen and (min-width: 1170px) {
    .cd-primary-nav {
        padding: 0 30px 0 0;
    }
}

@media only screen and (max-width: 767px)
{
    .ui.search.dropdown .menu {
        max-height: 16rem;
    }
}

.ui.input input {
    border-radius: 0;
}

.ui.selection.dropdown {
    border-radius: 0;
    margin-bottom: 5px;
}

/*IE 8*/
.ie .nhs-wales-logo {
    width: 50%;
}

.ie .cd-primary-nav {
    padding: 0 30px 0 0;
}

.ie9 .dropdown.item.ui.visible > .menu,
.ie9 .dropdown.selection.ui.visible > .menu {
   display: block !important;
   visibility: visible !important;
}

p:first-child {
    margin-top: 0em;
}

h1, h2, h3, h4, h5 {
    line-height: 1.28571429em;
    margin: calc(2rem - 0.14285714em ) 0em 1rem;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: bold;
}

p {
    line-height: 1.4285em;
}

b, strong {
    font-weight: bold;
}

.privacy-policy-bold {
    font-weight: bold;
}

.image-gallery-description {
    bottom: 0 !important;
    width: 100% !important;
    font-size: 1.2em;
    font-weight: bold;
    background-color: rgba(0,45,98,.8) !important;
}

.image-gallery-index {
    background-color:  rgba(0,45,98,.8) !important;
}

.banner-link {
    bottom: 47%;
    position: absolute;
    left: 15px;
    font-size: 0.8em;
    border: 1px solid;
}

@media only screen and (min-width: 420px) {
    .banner-link {
        bottom: 60px;
        font-size: 1.2em;
    }
}

@media only screen and (min-width: 1250px) {
    .image-gallery-slide-wrapper.right {
        width: calc(100% - 313px) !important;
    }
    
    .image-gallery-thumbnail {
        width: 300px !important;
    }
    
    .image-gallery-thumbnails-wrapper.right {
        width: 308px !important;
    }    
}

*, *:before, *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.rt-tabwrap {
    border-radius: 0px !important;
    background-color: #ffffff !important;
    box-shadow: 3px 3px 5px #c3c3c3 !important;
    border: 0px !important;
}

.rt-tabwrap input.tab {
	position:absolute;
	left:-999em;
}
.rt-tabwrap label.tab {
	float:left;
    padding: 0.92857143em 1.42857143em;
	overflow:hidden;
    cursor:pointer;
    font-size: inherit;
    margin-top: inherit;
    width: auto;
    border: 0px !important;
    border-radius: 0px !important;
    box-shadow: 3px 3px 5px #c3c3c3 !important;
    font-weight: bold;
    margin-bottom: -1px;
}
.rt-tabwrap{overflow:hidden;width:100%;}
.rt-tabwrap .tabs {
	display:table;
	width:100%;
	white-space:nowrap;
	clear:both;
    word-spacing:-.25em;
}
.rt-tabwrap .content {
	display:inline-block;
	vertical-align:top;
	border-bottom:none;
	white-space:normal;
	width:100%;
	margin-right:-100%;
	word-spacing:0;
}
.rt-tabwrap .content-inner{padding:10px;}
[type=radio]:checked + label  { border-bottom: 5px solid #78C0D4 !important ;}
.rt-tabwrap .tab[type=radio] ~ .tabs .content .content-inner {
	opacity: 0;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-transform: translateX(100%);
 	-moz-transform:    translateX(100%);
 	-ms-transform:     translateX(100%);
 	-o-transform:      translateX(100%);
 	transform:         translateX(100%);
	-webkit-transition: all 0.6s ease;
	-moz-transition:    all 0.6s ease;
	-ms-transition:     all 0.6s ease;
	-o-transition:      all 0.6s ease;
    transition:      all 0.6s ease;
}
.rt-tabwrap .tab1[type=radio] ~ .tabs .content1 { height: 0; }
.rt-tabwrap .tab2[type=radio] ~ .tabs .content2 { height: 0; }
.rt-tabwrap .tab3[type=radio] ~ .tabs .content3 { height: 0; }
.rt-tabwrap .tab4[type=radio] ~ .tabs .content4 { height: 0; }
.rt-tabwrap .tab5[type=radio] ~ .tabs .content5 { height: 0; }

.rt-tabwrap .tab1[type=radio]:checked ~ .tabs .content1 .content-inner,
.rt-tabwrap .tab2[type=radio]:checked ~ .tabs .content2 .content-inner,
.rt-tabwrap .tab3[type=radio]:checked ~ .tabs .content3 .content-inner,
.rt-tabwrap .tab4[type=radio]:checked ~ .tabs .content4 .content-inner,
.rt-tabwrap .tab5[type=radio]:checked ~ .tabs .content5 .content-inner  {
 opacity: 1;
 -webkit-transform: translateX(0%);
 -moz-transform:    translateX(0%);
 -ms-transform:     translateX(0%);
 -o-transform:      translateX(0%);
 transform:         translateX(0%);
}
.rt-tabwrap .tab1[type=radio]:checked  ~ .tabs .content1 { height: inherit; }
.rt-tabwrap .tab2[type=radio]:checked  ~ .tabs .content2 { height: inherit; }
.rt-tabwrap .tab3[type=radio]:checked  ~ .tabs .content3 { height: inherit; }
.rt-tabwrap .tab4[type=radio]:checked  ~ .tabs .content4 { height: inherit; }
.rt-tabwrap .tab5[type=radio]:checked  ~ .tabs .content5 { height: inherit; }

@media screen and (max-width:829px){
    .rt-tabwrap label.tab {float:none;display:block}	
	.rt-tabwrap .tabs{display:block}
}

.dentist-phone {
    padding-bottom: 5px;
}

.submit-btn {
    font-size: 1.6rem !important;
    font-weight: 400 !important;
}

button.image-gallery-thumbnail {
    margin-top: 0;
}

.image-gallery-thumbnails-wrapper.right {
    margin: 0 0 0 5px;
}

.image-gallery-image img {
    width: 100%
}