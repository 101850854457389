﻿.component-error {
  color: #9f3a38;
  box-shadow: none;
  background-color: #fff6f6;
  border: 1px solid #e0b4b4;
  border-radius: 0;
  margin-bottom: 5px;
  padding: 5px;
}

.field-validation-error {
  color: #9f3a38;
  padding-left: 5px;
  font-weight: 700;
}

h3 {
  font-size: 1.6rem;
}

.ols-table {
  border: 1px solid #78c0d4;
}

.ols-row {
  background: #a5d5e2;
}

div.ols-table > div:nth-of-type(2n+1) {
  background: #d2eaf1;
}

.ols-row-cell {
  height: 90px;
  border: 1px solid #78c0d4;
}

#contact-us-btn {
  margin-top: 18px;
  margin-left: 25px;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
}

@media only screen and (min-width: 600px) {
  .nhs-wales-logo {
    width: 50%;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-primary-nav {
    padding: 0 30px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .ui.search.dropdown .menu {
    max-height: 16rem;
  }
}

.ui.input input {
  border-radius: 0;
}

.ui.selection.dropdown {
  border-radius: 0;
  margin-bottom: 5px;
}

.ie .nhs-wales-logo {
  width: 50%;
}

.ie .cd-primary-nav {
  padding: 0 30px 0 0;
}

.ie9 .dropdown.item.ui.visible > .menu, .ie9 .dropdown.selection.ui.visible > .menu {
  visibility: visible !important;
  display: block !important;
}

p:first-child {
  margin-top: 0;
}

h1, h2, h3, h4, h5 {
  margin: calc(2rem - .142857em) 0 1rem;
  font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-weight: bold;
  line-height: 1.28571em;
}

p {
  line-height: 1.4285em;
}

b, strong, .privacy-policy-bold {
  font-weight: bold;
}

.image-gallery-description {
  font-size: 1.2em;
  font-weight: bold;
  width: 100% !important;
  background-color: #002d62cc !important;
  bottom: 0 !important;
}

.image-gallery-index {
  background-color: #002d62cc !important;
}

.banner-link {
  border: 1px solid;
  font-size: .8em;
  position: absolute;
  bottom: 47%;
  left: 15px;
}

@media only screen and (min-width: 420px) {
  .banner-link {
    font-size: 1.2em;
    bottom: 60px;
  }
}

@media only screen and (min-width: 1250px) {
  .image-gallery-slide-wrapper.right {
    width: calc(100% - 313px) !important;
  }

  .image-gallery-thumbnail {
    width: 300px !important;
  }

  .image-gallery-thumbnails-wrapper.right {
    width: 308px !important;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

.rt-tabwrap {
  background-color: #fff !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: 3px 3px 5px #c3c3c3 !important;
}

.rt-tabwrap input.tab {
  position: absolute;
  left: -999em;
}

.rt-tabwrap label.tab {
  float: left;
  cursor: pointer;
  font-size: inherit;
  margin-top: inherit;
  width: auto;
  margin-bottom: -1px;
  padding: .928571em 1.42857em;
  font-weight: bold;
  overflow: hidden;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: 3px 3px 5px #c3c3c3 !important;
}

.rt-tabwrap {
  width: 100%;
  overflow: hidden;
}

.rt-tabwrap .tabs {
  width: 100%;
  white-space: nowrap;
  clear: both;
  word-spacing: -.25em;
  display: table;
}

.rt-tabwrap .content {
  vertical-align: top;
  white-space: normal;
  width: 100%;
  word-spacing: 0;
  border-bottom: none;
  margin-right: -100%;
  display: inline-block;
}

.rt-tabwrap .content-inner {
  padding: 10px;
}

[type="radio"]:checked + label {
  border-bottom: 5px solid #78c0d4 !important;
}

.rt-tabwrap .tab[type="radio"] ~ .tabs .content .content-inner {
  opacity: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -o-transition: all .6s ease;
  transition: all .6s;
  transform: translateX(100%);
}

.rt-tabwrap .tab1[type="radio"] ~ .tabs .content1, .rt-tabwrap .tab2[type="radio"] ~ .tabs .content2, .rt-tabwrap .tab3[type="radio"] ~ .tabs .content3, .rt-tabwrap .tab4[type="radio"] ~ .tabs .content4, .rt-tabwrap .tab5[type="radio"] ~ .tabs .content5 {
  height: 0;
}

.rt-tabwrap .tab1[type="radio"]:checked ~ .tabs .content1 .content-inner, .rt-tabwrap .tab2[type="radio"]:checked ~ .tabs .content2 .content-inner, .rt-tabwrap .tab3[type="radio"]:checked ~ .tabs .content3 .content-inner, .rt-tabwrap .tab4[type="radio"]:checked ~ .tabs .content4 .content-inner, .rt-tabwrap .tab5[type="radio"]:checked ~ .tabs .content5 .content-inner {
  opacity: 1;
  transform: translateX(0%);
}

.rt-tabwrap .tab1[type="radio"]:checked ~ .tabs .content1, .rt-tabwrap .tab2[type="radio"]:checked ~ .tabs .content2, .rt-tabwrap .tab3[type="radio"]:checked ~ .tabs .content3, .rt-tabwrap .tab4[type="radio"]:checked ~ .tabs .content4, .rt-tabwrap .tab5[type="radio"]:checked ~ .tabs .content5 {
  height: inherit;
}

@media screen and (max-width: 829px) {
  .rt-tabwrap label.tab {
    float: none;
    display: block;
  }

  .rt-tabwrap .tabs {
    display: block;
  }
}

.dentist-phone {
  padding-bottom: 5px;
}

.submit-btn {
  font-size: 1.6rem !important;
  font-weight: 400 !important;
}

button.image-gallery-thumbnail {
  margin-top: 0;
}

.image-gallery-thumbnails-wrapper.right {
  margin: 0 0 0 5px;
}

.image-gallery-image img {
  width: 100%;
}

/*# sourceMappingURL=index.69e684a9.css.map */
